import { render, staticRenderFns } from "./panel-preview-menu-recommendation.vue?vue&type=template&id=5bb3bb5d&scoped=true&"
import script from "./panel-preview-menu-recommendation.vue?vue&type=script&lang=js&"
export * from "./panel-preview-menu-recommendation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bb3bb5d",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CIcon: require('@chakra-ui/vue').CIcon, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CSpinner: require('@chakra-ui/vue').CSpinner, CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox})
