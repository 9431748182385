<template>
  <c-box>
    <BaseModal
      :is-open="isOpen"
      :close-on-overlay-click="false"
      :with-button-close="false"
      size="1000px"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-text
            :font-size="['16px','20px']"
            font-weight="700"
          >
            Tambah Sekaligus
          </c-text>
        </c-box>
      </template>
      <template #body>
        <c-flex
          padding-inline="16px"
          gap="8px"
          margin-top="16px"
        >
          <c-box
            width="100%"
            max-width="120px"
          >
            <FormSelect
              :value="menuType"
              label="Jenis Menu"
              placeholder="Pilih"
              is-required
              is-without-margin-bottom
              is-without-label
              :options="[
                {
                  value: 'singleEntry',
                  label: 'Single',
                },
                {
                  value: 'multipleEntry',
                  label: 'Multiple',
                },
              ]"
              :is-disabled="false"
              :is-invalid="false"
              :invalid-text="'xxxxx'"
              @input="(val) => {
                menuType = val
                menuName = null
                menusArray = [
                  {
                    _menuType: val,
                    foodName: null,
                    servingSize: null,
                    servingSizeUnit: null,
                    servingSizeUnitOther: null,
                    photoUrl: null,
                    mealGlossariesId: null,
                  },
                ]
                menusObject = {
                  _menuType: val,
                  servingSize: null,
                  servingSizeUnit: null,
                  servingSizeUnitOther: null,
                  photoUrl: null,
                  mealGlossariesId: null,
                }
              }"
              @blur="() => {}"
            />
          </c-box>

          <!-- single -->
          <c-flex
            width="100%"
            v-if="menuType === 'singleEntry'"
            gap="8px"
          >
            <c-box width="100%">
              <FormInput
                v-model="menuName"
                label="Nama Menu"
                placeholder="Masukkan Nama Menu"
                is-required
                :is-disabled="false"
                is-without-margin-bottom
                is-without-label
                invalid-text="Cek Ulang Data"
                :is-invalid="!!formatForPayload.zodParse?.error?.format()?.sourceMenu?.menuName"
              />
            </c-box>
            <c-flex
              width="100%"
              flex-direction="column"
              max-width="300px"
            >
              <InputTextWithSelect
                custom-width-select="130px"
                label="Jumlah Takaran"
                input-placeholder="Masukkan Jumlah Takaran"
                select-placeholder="Pilih"
                :input-value="menusObject.servingSize"
                :select-value="menusObject.servingSizeUnit"
                :select-other-value="menusObject.servingSizeUnitOther"
                :select-options="optionPortionUnits"
                :is-disabled="false"
                is-without-margin-bottom
                @blur:input="() => {}"
                @blur:select="() => {}"
                @blur:select-other="() => {}"
                @change:input="(v_) => menusObject.servingSize = v_"
                @change:select="(v_) => menusObject.servingSizeUnit = v_"
                @change:select-other="(v_) => menusObject.servingSizeUnitOther = v_"
                is-without-label
                invalid-input-text="Cek Ulang Data"
                :is-invalid-input="!!formatForPayload.zodParse?.error?.format()?.sourceMenu?.menus?.servingSize"
                is-invalid-select-text="Cek Ulang Data"
                :is-invalid-select="!!formatForPayload.zodParse?.error?.format()?.sourceMenu?.menus?.servingSizeUnit"
                is-invalid-select-other-text="Lainnya wajib diisi"
                :is-invalid-select-other="!!formatForPayload.zodParse?.error?.format()?.sourceMenu?.menus?.servingSizeUnitOther"
              />
            </c-flex>
            <c-box>
              <InputImage2
                :custom-style="{ width: ['48px','62px'], height: ['48px','62px'] }"
                :with-bottom-text="false"
                :photo-url="menusObject.photoUrl"
                :is-disabled="false"
                @delete:photo="() => {
                  menusObject.photoUrl = null
                  menusObject.mealGlossariesId = null
                }"
                @click:photo="() => {
                  popupIsOpen = true
                }"
              />
            </c-box>
          </c-flex>

          <!-- multiple -->
          <c-flex
            width="100%"
            v-if="menuType === 'multipleEntry'"
            gap="8px"
            flex-direction="column"
          >
            <c-flex
              width="100%"
              gap="8px"
            >
              <c-box width="100%">
                <FormInput
                  v-model="menuName"
                  label="Nama Menu"
                  placeholder="Masukkan Nama Menu"
                  is-required
                  :is-disabled="false"
                  is-without-margin-bottom
                  is-without-label
                  invalid-text="Cek Ulang Data"
                  :is-invalid="!!formatForPayload.zodParse?.error?.format()?.sourceMenu?.menuName"
                />
              </c-box>
              <c-box width="100%">
                <FormInput
                  v-model="menusArray[0].foodName"
                  label="Komposisi Makanan"
                  placeholder="Masukkan Komposisi Makanan"
                  is-required
                  :is-disabled="false"
                  is-without-margin-bottom
                  is-without-label
                  invalid-text="Cek Ulang Data"
                  :is-invalid="!!formatForPayload.zodParse?.error?.format()?.sourceMenu?.menus?.['0']?.foodName"
                />
              </c-box>
              <c-flex
                width="100%"
                flex-direction="column"
              >
                <InputTextWithSelect
                  custom-width-select="130px"
                  label="Jumlah Takaran"
                  input-placeholder="Masukkan Jumlah Takaran"
                  select-placeholder="Pilih"
                  :input-value="menusArray[0].servingSize"
                  :select-value="menusArray[0].servingSizeUnit"
                  :select-other-value="menusArray[0].servingSizeUnitOther"
                  :select-options="optionPortionUnits"
                  :is-disabled="false"
                  @blur:input="() => {}"
                  @blur:select="() => {}"
                  @blur:select-other="() => {}"
                  @change:input="(v_) => menusArray[0].servingSize = v_"
                  @change:select="(v_) => menusArray[0].servingSizeUnit = v_"
                  @change:select-other="(v_) => menusArray[0].servingSizeUnitOther = v_"
                  is-without-margin-bottom
                  is-without-label
                  invalid-input-text="Cek Ulang Data"
                  :is-invalid-input="!!formatForPayload.zodParse?.error?.format()?.sourceMenu?.menus?.['0']?.servingSize"
                  is-invalid-select-text="Cek Ulang Data"
                  :is-invalid-select="!!formatForPayload.zodParse?.error?.format()?.sourceMenu?.menus?.['0']?.servingSizeUnit"
                  is-invalid-select-other-text="Lainnya wajib diisi"
                  :is-invalid-select-other="!!formatForPayload.zodParse?.error?.format()?.sourceMenu?.menus?.['0']?.servingSizeUnitOther"
                />
              </c-flex>
              <c-box>
                <InputImage2
                  :custom-style="{ width: ['48px','62px'], height: ['48px','62px'] }"
                  :with-bottom-text="false"
                  :photo-url="menusArray[0].photoUrl"
                  :is-disabled="false"
                  @delete:photo="() => {
                    menusArray[0].photoUrl = null
                    menusArray[0].mealGlossariesId = null
                  }"
                  @click:photo="() => {
                    popupIsOpen = true
                    popupItemIndexes = [0]
                  }"
                />
              </c-box>
              <c-box v-if="menusArray.length >= 2">
                <c-box width="52px">
                  &nbsp;
                </c-box>
              </c-box>
            </c-flex>
            <!--          -->
            <TestFieldArray
              v-slot="{
                push: push2,
                remove: remove2
              }"
            >
              <c-flex
                v-for="(v2, i2) in menusArray"
                v-if="i2 >= 1"
                :key="i2"
                margin-top="8px"
                width="100%"
                gap="8px"
              >
                <c-box width="100%" />
                <c-box width="100%">
                  <FormInput
                    v-model="v2.foodName"
                    label="Komposisi Makanan"
                    placeholder="Masukkan Komposisi Makanan"
                    is-required
                    :is-disabled="false"
                    is-without-margin-bottom
                    is-without-label
                    invalid-text="Cek Ulang Data"
                    :is-invalid="!!formatForPayload.zodParse?.error?.format()?.sourceMenu?.menus?.[i2]?.foodName"
                  />
                </c-box>
                <c-flex
                  width="100%"
                  flex-direction="column"
                >
                  <InputTextWithSelect
                    custom-width-select="130px"
                    label="Jumlah Takaran"
                    input-placeholder="Masukkan Jumlah Takaran"
                    select-placeholder="Pilih"
                    :input-value="v2.servingSize"
                    :select-value="v2.servingSizeUnit"
                    :select-other-value="v2.servingSizeUnitOther"
                    :select-options="optionPortionUnits"
                    :is-disabled="false"
                    @blur:input="() => {}"
                    @blur:select="() => {}"
                    @blur:select-other="() => {}"
                    @change:input="(v_) => v2.servingSize = v_"
                    @change:select="(v_) => v2.servingSizeUnit = v_"
                    @change:select-other="(v_) => v2.servingSizeUnitOther = v_"
                    is-without-margin-bottom
                    is-without-label
                    invalid-input-text="Cek Ulang Data"
                    :is-invalid-input="!!formatForPayload.zodParse?.error?.format()?.sourceMenu?.menus?.[i2]?.servingSize"
                    is-invalid-select-text="Cek Ulang Data"
                    :is-invalid-select="!!formatForPayload.zodParse?.error?.format()?.sourceMenu?.menus?.[i2]?.servingSizeUnit"
                    is-invalid-select-other-text="Lainnya wajib diisi"
                    :is-invalid-select-other="!!formatForPayload.zodParse?.error?.format()?.sourceMenu?.menus?.[i2]?.servingSizeUnitOther"
                  />
                </c-flex>
                <c-box>
                  <InputImage2
                    :custom-style="{ width: ['48px','62px'], height: ['48px','62px'] }"
                    :with-bottom-text="false"
                    :photo-url="v2.photoUrl"
                    :is-disabled="false"
                    @delete:photo="() => {
                      v2.photoUrl = null
                      v2.mealGlossariesId = null
                    }"
                    @click:photo="() => {
                      popupIsOpen = true
                      popupItemIndexes = [i2]
                    }"
                    is-without-margin-bottom
                  />
                </c-box>
                <c-flex
                  margin-bottom="8px"
                >
                  <c-flex align-items="center">
                    <c-button
                      background-color="transparent"
                      :_hover="{ backgroundColor: 'neutral.lightGray' }"
                      margin-left="auto"
                      @click="remove2(menusArray, i2)"
                    >
                      <c-box
                        height="20px"
                        width="20px"
                      >
                        <inline-svg
                          :src="require('@/assets/icons/icon-trash.svg')"
                          height="20"
                          width="20"
                          fill="#D32737"
                        />
                      </c-box>
                    </c-button>
                  </c-flex>
                </c-flex>
              </c-flex>

              <c-flex
                justify-content="flex-end"
                margin-top="8px"
              >
                <BaseButton
                  size="medium"
                  border-radius="1000px"
                  variant="outlined"
                  width="70%"
                  background-color="white"
                  :left-svg-icon="require('@/assets/icons/icon-plus.svg')"
                  left-svg-icon-color="#008C81"
                  @click.prevent="push2(menusArray, {
                    _menuType: menuType,
                    foodName: null,
                    servingSize: null,
                    servingSizeUnit: null,
                    servingSizeUnitOther: null,
                    photoUrl: null,
                    mealGlossariesId: null
                  })"
                >
                  Tambah Komposisi Makanan
                </BaseButton>
              </c-flex>
            </testfieldarray>
            <!--          -->
          </c-flex>
        </c-flex>

        <c-box
          margin-top="16px"
          display="flex"
          flex-direction="column"
        >
          <c-text
            :font-size="['14px', '16px']"
            color="primary.400"
            px="16px"
          >
            Tambah menu ke hari
          </c-text>
          <c-box :margin-top="['8px','14px']">
            <ChipDays
              :start-at="currentActiveDays[0]"
              :end-at="currentActiveDays[currentActiveDays.length - 1]"
              :selected-day="getSelectedDay"
              :invalid-day-validation="chipDaysState.invalidDayValidation"
              custom-item-min-width="70px"
              :disabled-day="chipDaysState.disabledDay"
              :days-state="formatForPayload.data?.destination?.reduce((acc,curr) => {
                acc[curr.day] = 'haha'
                return acc
              }, {})"
              custom-item-width="100%"
              custom-template-columns="repeat(10, 1fr)"
              @on-change-day="(day) => selectedDay = day"
            />
            <c-box
              v-if="!!getSelectedDay && !chipDaysState.disabledDay?.some((v) => v === getSelectedDay)"
              margin-top="16px"
              as="ul"
              padding-inline="16px"
              list-style-type="none"
              display="flex"
              flex-direction="column"
              gap="16px"
            >
              <c-box
                v-for="v in listMenuCategory"
                as="li"
                :key="v.id"
                v-if="!chipDaysState?.mealtimeStates?.[v.id]?.isSkipped"
              >
                <c-flex
                  background-color="primary.400"
                  color="white"
                  :height="['45px', '52px']"
                  font-weight="500"
                  :font-size="['16px','18px']"
                  padding-inline="16px"
                  padding-block="8px"
                  align-items="center"
                  border-radius="12px"
                  justify-content="space-between"
                >
                  <span>
                    {{ v.label }}
                  </span>
                  <c-checkbox
                    :is-checked="!!listChecked?.[getSelectedDay]?.[v.id]"
                    @change="(_, $e) => handleCheckbox(v.id, $e)"
                    v-chakra="{
                      '&  div': {
                        width: '30px',
                        height: '30px',
                      },
                      '&  svg': {
                        width: '15px',
                        height: '15px',
                      },
                      '& :not(input[type=checkbox]:checked) + div': {
                        backgroundColor: 'white',
                        borderColor: 'white',
                        color: 'white',
                      },
                      '& input[type=checkbox]:checked + div' : {
                        backgroundColor: 'primary.50',
                        borderColor: 'primary.50',
                        color: 'primary.500',
                      },
                    }"
                  />
                </c-flex>
              </c-box>

              <c-text
                v-if="formatForPayload.zodParse?.error?.format()?.destination"
                d="flex"
                align-items="center"
                gap="8px"
                color="#D32737"
              >
                <c-box
                  :min-height="['13px', '16px']"
                  :min-width="['13px', '16px']"
                  :height="['13px', '16px']"
                  :width="['13px', '16px']"
                >
                  <inline-svg
                    :src="require('@/assets/icons/icon-circle-warn.svg')"
                    height="100%"
                    width="100%"
                    fill="#D32737"
                  />
                </c-box>
                Cek Ulang Data
              </c-text>
            </c-box>
          </c-box>
        </c-box>
      </template>
      <template #footer>
        <c-flex
          gap="16px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-button
            font-size="18px"
            font-weight="500"
            variant-color="primary"
            border-radius="calc(infinity * 1px)"
            variant="outline"
            width="100%"
            size="lg"
            @click="close"
          >
            Batal
          </c-button>
          <c-button
            font-size="18px"
            font-weight="500"
            variant-color="secondary"
            color="primary.400"
            background-color="secondary.400"
            border-radius="calc(infinity * 1px)"
            width="100%"
            size="lg"
            @click="save"
            :disabled="formatForPayload.zodParse.success === false || isSaving"
            :_disabled="{
              backgroundColor: 'neutral.lightGray',
              color: 'neutral.white',
              cursor: 'not-allowed',
            }"
          >
            Simpan
          </c-button>
        </c-flex>
      </template>
    </BaseModal>
    <ModalChooseMenu
      :is-open="popupIsOpen"
      title="Pilih Tampilan"
      :is-loading-data-food="popupIsLoadingDataFood"
      :options="popupOptions"
      :selected-foods="popupSelectedMenu"
      :pages="popupPages"
      :filter="popupFilter"
      @change:selected-food="(menu) => {
        if (menusArray?.[popupItemIndexes?.[0]]) {
          menusArray[popupItemIndexes[0]].photoUrl = menu?.photoUrl || ''
          menusArray[popupItemIndexes[0]].mealGlossariesId = menu?.id || ''
        } else if (menusObject) {
          menusObject.photoUrl = menu?.photoUrl || ''
          menusObject.mealGlossariesId = menu?.id || ''
        }
        popupIsOpen = false
      }"
      @change:is-open="(open) => popupIsOpen = open"
      @change:filter-page="(page) => popupFilter.page = page"
      @change:filter-search="(search) => {
        popupFilter.page = 1
        popupFilter.search = search
      }"
    />

    <ModalSimpleInfo
      :is-open="isOpenModalSuccessSaveData"
      :image="require('@/assets/images/success-image.svg')"
      title="Tambah sekaligus jam makan berhasil"
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
        >
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="closeAndRouterPush"
          >
            Oke
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>
  </c-box>
</template>

<script>
import { CBox, CButton, CFlex } from '@chakra-ui/vue'
import BaseModal from '@/components/elements/base-modal.vue'
import ChipDays from '@/components/chip-days.vue'
import InputTextWithSelect from '@/components/input-text-with-select.vue'
import FormInput from '@/views/client/kuisioner/forms/form-input-2.vue'
import InputImage2 from '@/components/input-image-2.vue'
import FormSelect from '@/views/client/kuisioner/forms/form-select-2.vue'
import { listMenuCategory } from '@/utils/get-menu-category'
import BaseButton from '@/components/elements/base-button.vue'
import TestFieldArray from '@/views/profile/detail/test-field-array.vue'
import ModalChooseMenu from '@/components/modal-choose-menu.vue'
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import { z } from 'zod'
import { parseErrorCatch } from '@/utils/parse-error-catch'
import ModalSimpleInfo from '@/components/elements/modal-simple-info.vue'

export default {
  name: 'ModalCreateBatchMenuRecommendations',
  components: {
    ModalSimpleInfo,
    ModalChooseMenu,
    TestFieldArray,
    BaseButton, FormSelect, CFlex, InputImage2, FormInput, InputTextWithSelect, CButton, ChipDays, BaseModal, CBox },
  props: {
    isOpen: {
      type: Boolean,
      default: false, // TODO
    },
    optionPortionUnits: {
      type: Array,
      default: () => [],
    },
    chipDaysState: {
      type: Object,
      default: () => ({
        invalidDayValidation: [],
        disabledDay: [],
        mealtimeStates: {},
      }),
    },
    // beacuse clientId2 has global computed value
    clientId2: {
      type: String,
      default: null,
    },
    // beacuse programId has global computed value
    programId2: {
      type: String,
      default: null,
    },
    month: {
      type: String,
      default: null,
    },
    selectedDayDefaultValue: {
      type: [Number, String],
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      isSaving: false,
      isOpenModalSuccessSaveData: false,
      //
      selectedDay: 0,
      currentActiveDays: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      listChecked: {},
      //

      menuType: 'singleEntry',
      menuName: null,
      menusArray: [
        {
          _menuType: 'multipleEntry',
          foodName: null,
          servingSize: null,
          servingSizeUnit: null,
          servingSizeUnitOther: null,
          photoUrl: null,
        },
      ],
      menusObject: {
        servingSize: null,
        servingSizeUnit: null,
        servingSizeUnitOther: null,
        photoUrl: null,
        mealGlossariesId: null,
      },

      popupIsLoadingDataFood: false,
      popupItemIndexes: [],
      popupIsOpen: false,
      popupFilter: {
        search: '',
        page: 1,
        limit: 10,
      },
      popupMeta: {
        total: 0,
        page: 0,
        pagesize: 0,
      },
      popupSelectedMenu: {},
      popupPages: [1],
      popupOptions: [],
    }
  },
  computed: {
    getSelectedDay() {
      if (this.selectedDay)
        return this.selectedDay
      return this.selectedDayDefaultValue
    },
    listMenuCategory() {
      return listMenuCategory
    },
    formatForPayload() {
      const SingleEntryMenuSchema = z.object({
        servingSize: z.string().min(1),
        servingSizeUnit: z.string().min(1),
        servingSizeUnitOther: z.string().nullable(),
        mealGlossariesId: z.string().nullable(),
        photoUrl: z.string().nullable(),
      }).superRefine((data, ctx) => {
        if (isNaN(data.servingSize) && !/[\W\d]+/.test(data.servingSize)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['servingSize'],
            message: 'servingSize be decimal or fraction',
          })
        }

        if (data.servingSizeUnit === 'Lainnya' && !data.servingSizeUnitOther) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['servingSizeUnitOther'],
            message: "servingSizeUnitOther is required when servingSizeUnit is 'Lainnya'",
          })
        }
      })

      const MultipleEntryItemSchema = z.array(z.object({
        foodName: z.string().min(1),
        servingSize: z.string().min(1),
        servingSizeUnit: z.string().min(1),
        servingSizeUnitOther: z.string().nullable(),
        mealGlossariesId: z.string().nullable(),
        photoUrl: z.string().nullable(),
      }).superRefine((data, ctx) => {
        if (data.servingSizeUnit === 'Lainnya' && !data.servingSizeUnitOther) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "servingSizeUnitOther is required when servingSizeUnit is 'Lainnya'",
          })
        }
        if (isNaN(data.servingSize) && !/[\W\d]+/.test(data.servingSize)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['servingSize'],
            message: 'servingSize be decimal or fraction',
          })
        }
      }))

      const MealPlanSchema = z.object({
        clientId: z.string().uuid(),
        programId: z.string().uuid(),
        month: z.string().transform(val => {
          const parsed = parseInt(val, 10)
          if (isNaN(parsed)) {
            throw new Error('Month must be a valid number')
          }
          return parsed
        }).refine(val => val >= 1 && val <= 12, { message: 'Month must be between 1 and 12' }),
        sourceMenu: z.object({
          menuName: z.string().min(1),
          menuType: z.enum(['singleEntry', 'multipleEntry']),
          menus: z.union([
            SingleEntryMenuSchema,
            MultipleEntryItemSchema,
          ]),
        }),
        destination: z.array(
          z.object({
            day: z.string().transform(val => {
              const parsed = parseInt(val, 10)
              if (isNaN(parsed)) {
                throw new Error('Month must be a valid number')
              }
              return parsed
            }).refine(val => val >= 1 && val <= 12, { message: 'Month must be between 1 and 12' }),
            mealTime: z.array(z.string()),
          }),
        ).min(1),
      })
      //

      let menus = this.menusObject
      if (this.menuType === 'multipleEntry') {
        menus = this.menusArray
      }
      const data = {
        clientId: this.clientId2,
        programId: this.programId2,
        month: this.month,
        sourceMenu: {
          menuType: this.menuType,
          menuName: this.menuName,
          menus,
        },
        destination: Object.entries(this.listChecked)?.reduce((acc_, [day, obj]) => {
          const mealTime = Object.entries(obj).reduce((acc, [currMealTime, currBoolean]) => {
            if (currBoolean) {
              acc.push(currMealTime)
            }
            return acc
          }, [])
          if (mealTime.length) {
            acc_.push({
              day,
              mealTime,
            })
          }
          return acc_
        }, []),
      }

      return {
        data: data,
        zodParse: MealPlanSchema.safeParse(data),
      }
    },
  },
  watch: {
    popupFilter: {
      // Fetch Popup Content
      async handler(popupFilter) {
        this.popupIsLoadingDataFood = true
        const resPopupContent = await reqNutriMealPlan.getMealGlossaries(this.$store.getters.axios, {
          q: popupFilter?.search,
          page: popupFilter?.page,
          perpage: popupFilter?.limit,
        })
        const dataPopupContent = resPopupContent.data
        if (dataPopupContent) {
          this.popupMeta = dataPopupContent?.meta
          this.popupOptions = dataPopupContent?.data?.result
          this.popupPages = Array.from({ length: Math.ceil(this.popupMeta.total / this.popupMeta.pagesize) }, (_, i) => i + 1)
        }
        this.popupIsLoadingDataFood = false
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    resetAllInput() {
      this.selectedDay = 0,
      this.listChecked = {},
      //

      this.menuType = 'singleEntry'
      this.menuName = null
      this.menusArray = [
        {
          _menuType: 'multipleEntry',
          foodName: null,
          servingSize: null,
          servingSizeUnit: null,
          servingSizeUnitOther: null,
          photoUrl: null,
        },
      ]
      this.menusObject = {
        servingSize: null,
        servingSizeUnit: null,
        servingSizeUnitOther: null,
        photoUrl: null,
        mealGlossariesId: null,
      }

      this.popupIsLoadingDataFood= false,
      this.popupItemIndexes= []
      this.popupIsOpen= false
      this.popupFilter= {
        search: '',
        page: 1,
        limit: 10,
      }
      this.popupMeta= {
        total: 0,
        page: 0,
        pagesize: 0,
      }
      this.popupSelectedMenu= {}
      this.popupPages= [1]
      this.popupOptions= []
    },
    handleCheckbox(key, e) {
      if (!this.listChecked?.[this.getSelectedDay]) {
      // handle reactive manually
        this.$set(this.listChecked, this.getSelectedDay, {})
      }
      // handle reactive manually
      this.$set(this.listChecked[this.getSelectedDay], key, e.target.checked)
    },
    async save() {
      try {
        this.isSaving = true
        await reqNutriMealPlan.reqNutri_mealPlans_menuRecommendation_bulkCreate(this.$store.getters.axios, this.formatForPayload.data)
        this.isOpenModalSuccessSaveData = true
      // open susccess modal
      } catch (e) {
        this.$toast({
          status: 'error',
          title: 'Error',
          description: parseErrorCatch(e),
          duration: 3000,
        })
      } finally {
        this.isSaving = false
      }
    },
    close() {
      this.$emit('close')
      this.isOpenModalSuccessSaveData = false
      this.resetAllInput()
    },
    closeAndRouterPush() {
      this.$router.replace({
        ...this.$route,
        query: { ...this.$route.query, refresh: Date.now() },
      })
      close()
    },
  },
}
</script>
