var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "justify-content": "space-between",
      "align-items": "center",
      "background-color": "#C7F9E333",
      "padding-block": "16px",
      "padding-inline": "12px",
      "border-radius": "12px",
      "gap": "4px",
      "border-bottom-width": "1px",
      "border-color": "neutral.lightGray"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "600",
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s(_vm.mealTime) + " - " + _vm._s(_vm.caloriesAmount) + "kkal ")]), _c('c-flex', {
    attrs: {
      "justify-content": "center",
      "align-items": "center",
      "flex-shrink": "0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "color": "neutral.gray"
    }
  }, [_vm._v(" " + _vm._s(_vm.startTime) + " - " + _vm._s(_vm.endTime) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }