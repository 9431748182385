<template>
  <c-box>
    <c-flex
      padding-block="16px"
      box-shadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
    >
      <!-- Header Panduan Porsi -->
      <c-flex
        flex-direction="column"
        flex-shrink="0"
        width="330px"
        padding-inline="16px"
      >
        <c-flex
          :height="['34px', '50px']"
          align-items="center"
        >
          <c-text
            color="neutral.superDarkGray"
            font-size="22px"
            font-weight="600"
            margin-right="auto"
          >
            Panduan Porsi
          </c-text>
        </c-flex>
        <c-flex
          justify-content="flex-start"
          height="auto"
          padding-block="8px"
          overflow-x="auto"
          grid-gap="8px"
          v-dragscroll
        >
          <c-button
            v-for="option in optionsPanduanPorsi"
            :key="option.id"
            v-chakra="{
              '&:disabled': {
                backgroundColor: 'neutral.superLightGray',
                borderColor: 'neutral.888888',
                color: 'neutral.888888',
              },
            }"
            type="button"
            :h="['30px', '40px']"
            :px="['8px', '16px']"
            m="0"
            :font-size="['12px', '16px']"
            line-height="24px"
            rounded="full"
            :class="selectedPanduanPorsi?.id === option?.id ? 'active': ''"
            :color="selectedPanduanPorsi?.id === option?.id ? 'white' : 'primary.400'"
            :bg="selectedPanduanPorsi?.id === option?.id ? '#007067' : 'white'"
            border-width="1px"
            border-style="solid"
            :border-color="selectedPanduanPorsi?.id === option?.id ? '#007067' : 'primary.400'"
            white-space="nowrap"
            font-weight="400"
            flex-shrink="0"
            :disabled="option?.disabled"
            :_hover="{
              backgroundColor: selectedPanduanPorsi?.id === option?.id ? '#007067' : 'primary.50',
            }"
            @click.stop.prevent="onChangePanduanPorsi(option)"
          >
            {{ option?.label }}
          </c-button>
        </c-flex>
      </c-flex>

      <c-divider
        orientation="vertical"
        border-color="neutral.888888"
        margin-right="8px"
      />

      <!-- Pilihan Hari Rekomendasi Menu -->
      <c-box
        flex-grow="1"
        width="100%"
        padding-right="16px"
        padding-left="8px"
      >
        <c-flex
          width="100%"
          align-items="center"
          justify-content="space-between"
        >
          <c-button
            variant="ghost"
            width="30px"
            min-width="30px"
            height="30px"
            margin="0"
            padding="0"
            margin-right="16px"
            @click="
              $router.push({
                ...$route,
                name: 'nutri.meal-plan.menu-recommendation.detail',
              })
            "
          >
            <c-box
              :width="['12px', '15px']"
              :height="['12px', '15px']"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-arrow-down.svg')"
                height="100%"
                width="100%"
                fill="#008C81"
                style="transform: rotate(90deg)"
              />
            </c-box>
          </c-button>
          <c-text
            color="neutral.superDarkGray"
            font-size="22px"
            font-weight="600"
            margin-right="auto"
          >
            Rekomendasi Menu
          </c-text>
          <c-tooltip
            :label="iconCloud.text"
            placement="bottom"
          >
            <c-box
              :width="['35px']"
              :height="['35px']"
              margin-right="16px"
              :class="saveState === null ? 'animate-spin' : ''"
            >
              <inline-svg
                :src="iconCloud.icon"
                height="100%"
                width="100%"
                fill="#008C81"
              />
            </c-box>
          </c-tooltip>
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-save.svg')"
            left-svg-icon-color="white"
            :disabled="
              !isValidAllFormForAllDays ||
                saveState === null ||
                isLoadingSave ||
                isSaveDraft === 1
            "
            @click="onSave"
          >
            Simpan
          </BaseButton>
        </c-flex>

        <!--  -->
        <c-flex
          width="100%"
          align-items="center"
          gap="0px"
          margin-top="0px"
        >
          <ChipDays
            :start-at="currentActiveDays[0]"
            :end-at="currentActiveDays[currentActiveDays.length - 1]"
            :selected-day="query.day"
            :invalid-day-validation="chipDaysState.invalidDayValidation"
            :disabled-day="
              isLoadingSave || saveState === null
                ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                : chipDaysState.disabledDay
            "
            :dot-badge-days="chipDaysState.dotBadgeDay"
            custom-item-width="100%"
            custom-item-min-width="50px"
            custom-template-columns="repeat(10, 1fr)"
            @on-change-day="
              (day) => {
                $router.push({
                  ...$route,
                  query: {
                    ...$route.query,
                    day,
                  },
                })
              }
            "
          />

          <c-flex
            gap="8px"
            flex-shrink="0"
          >
            <ModalMenuDuplicate
              :is-valid-all-form="isValidAllForm ? true : false"
              :is-disabled="
                isLoadingSave ||
                  saveState === null
              "
              :meal-time-states="listMealTimeStates"
              @fetch-form-days="() => getFormDays()"
            />

            <c-button
              font-size="18px"
              font-weight="500"
              variant-color="secondary"
              color="primary.400"
              background-color="secondary.400"
              border-radius="calc(infinity * 1px)"
              size="lg"
              height="50px"
              :disabled="isLoadingSave || saveState === null || !getIsEditableForm"
              @click="
                () => {
                  if (isLoadingSave || saveState === null || !getIsEditableForm) return
                  dataModalCreateBatchMenuRecommendations.isOpen = true
                }
              "
            >
              <c-box
                as="span"
                font-size="30px"
                font-weight="400"
                padding-right="8px"
              >
                +
              </c-box>
              Sekaligus
            </c-button>
          </c-flex>
        </c-flex>
      </c-box>
    </c-flex>

    <c-flex
      width="100%"
      padding-block="16px"
    >
      <!-- Detail Panduan Porsi -->
      <c-flex
        id="container-portion-guide"
        flex-direction="column"
        flex-shrink="0"
        width="330px"
        height="100%"
        max-height="calc(100vh - 275px)"
        padding-bottom="calc(100vh - calc(100vh - 400px))"
        overflow-y="auto"
        padding-left="16px"
      >
        <c-flex
          v-if="previewMealPlanSchedule.length > 0"
          flex-direction="column"
          gap="16px"
        >
          <c-box
            v-for="(mealPlanSchedule) in previewMealPlanSchedule"
            :key="mealPlanSchedule?.mealTime"
            :id="`portion-guide-${mealPlanSchedule?.mealTime}`"
            class="pulse"
          >
            <MealTimeHeader
              :meal-time="constants.mealPlanValueToLabel[mealPlanSchedule?.mealTime]"
              :calories-amount="mealPlanSchedule?.caloriesAmount"
              :start-time="format24Time(mealPlanSchedule?.startTime)"
              :end-time="format24Time(mealPlanSchedule?.endTime)"
            />
            <c-accordion
              v-if="mealPlanSchedule?.mealComposition?.length > 0"
              :allow-toggle="true"
            >
              <CardMealPortionGuide
                v-for="(item, itemIdx) in mealPlanSchedule?.mealComposition"
                :key="`${mealPlanSchedule?.mealTime}-${itemIdx}`"
                :meal-type="item?.foodGroup"
                :meal-portion="`${item?.portion || '-'}`"
                :meal-portion-decimal="`${item?.portionDecimal || '0'}`"
                :meal-portion-unit="`Porsi`"
                :meal-items="
                  mergeMealItems(
                    item?.portionDecimal,
                    item?.mealItems,
                    item?.additionalMealItems
                  ) || []
                "
              />
            </c-accordion>
            <c-flex
              v-else
              justify-content="center"
              align-items="center"
              width="100%"
              height="100%"
            >
              <NoData2
                :text="`Tidak ada data ${constants.mealPlanValueToLabel[mealPlanSchedule?.mealTime]}`"
              />
            </c-flex>
          </c-box>
        </c-flex>
        <c-flex
          v-else
          justify-content="center"
          align-items="center"
          width="100%"
          height="100%"
        >
          <NoData2
            text="Tidak ada data"
          />
        </c-flex>
      </c-flex>

      <c-divider
        orientation="vertical"
        border-color="neutral.888888"
        margin-right="8px"
      />

      <!-- Form Rekomendasi Menu -->
      <c-box
        flex-grow="1"
        width="100%"
        max-height="calc(100vh - 275px)"
        overflow-y="auto"
        padding-right="8px"
        padding-left="8px"
      >
        <c-flex
          flex-direction="column"
          gap="16px"
        >
          <c-flex
            v-for="({ value: mealTime2 }, targetIdx) in constants.steps"
            :key="targetIdx"
            v-if="!listMealTimeStates?.[mealTime2]?.isSkipped"
            gap="8px"
          >
            <c-box
              width="238px"
              flex-shrink="0"
            >
              <c-flex
                width="100%"
                justify-content="space-between"
                align-items="center" 
                margin-bottom="8px"
              >
                <c-text
                  font-size="18px"
                  font-weight="600"
                  color="neutral.black"
                >
                  {{ constants.mealPlanValueToLabel?.[mealTime2] || '-' }}
                </c-text>
                <c-text
                  font-size="16px"
                  font-weight="500"
                  color="neutral.black"
                >
                  {{ previewMealPlanSchedule?.find((el) => el?.mealTime === mealTime2)?.caloriesAmount || '-' }}kkal
                </c-text>
              </c-flex>
              <PanelPreviewMenuRecommendation
                :id-program="$route.params.programId"
                :id-client="$route.params.clientId"
                :month="$route.params.month"
                :meal-time="mealTime2"
                :day="query.day"
                :total-days="10"
                :refetch="saveState"
              />
            </c-box>

            <!-- Form Menu Rekomendasi berdasarkan Jam Makan -->
            <c-flex
              flex-grow="1"
              flex-direction="column"
              justify-content="space-between"
            >
              <c-box
                v-if="listMealTimeStates?.[mealTime2]?.isSkipped"
                margin-bottom="16px"
              >
                <Info2
                  variant="warning"
                  text="Kamu tidak bisa mengisi Rekomendasi menu karena memilih tidak makan pada waktu makan ini"
                />
              </c-box>
              <c-box
                v-else-if="!isValidTabNutritionalProfile || !isValidTabMealPlan"
                margin-bottom="16px"
              >
                <Info2
                  variant="danger"
                  text="Kamu tidak bisa mengisi Rekomendasi Menu sebelum mengisi Rencana makan"
                />
              </c-box>

              <c-box>
                <c-flex
                  v-if="draftDuplicate?.[mealTime2]?.duplicationDetail?.isEdited === 0"
                  align-items="center"
                  justify-content="space-between"
                  margin-bottom="16px"
                >
                  <c-button
                    variant="solid"
                    variant-color="primary"
                    border-radius="30px"
                    font-weight="500"
                    font-size="18px"
                    @click="onOpenEditMenuDuplicate(mealTime2)"
                  >
                    <c-box
                      as="span"
                      :min-height="['20px', '22px']"
                      :min-width="['20px', '22px']"
                      :height="['20px', '22px']"
                      :width="['20px', '22px']"
                      margin-right="8px"
                    >
                      <inline-svg
                        :src="require('@/assets/icons/icon-edit-2.svg')"
                        height="100%"
                        width="100%"
                        fill="#FFF"
                      />
                    </c-box>
                    Edit Menu Duplikasi
                  </c-button>
                </c-flex>
                <TestFieldArray
                  v-slot="{ push, remove }"
                >
                  <c-flex
                    v-for="(v, i) in targets[mealTime2]"
                    :key="i + 'a'"
                    margin-top="14px"
                    :_first="{ marginTop: '4px' }"
                    gap="8px"
                  >
                    <c-box
                      width="110px"
                      flex-shrink="0"
                    >
                      <FormSelect
                        is-without-margin-bottom
                        :value="v.menuType"
                        label=""
                        placeholder="Pilih Jenis Menu"
                        :is-required="null"
                        is-without-label
                        size="md"
                        :options="[
                          {
                            value: 'singleEntry',
                            label: 'Single Entry',
                          },
                          {
                            value: 'multipleEntry',
                            label: 'Multiple Entry',
                          },
                        ]"
                        :is-disabled="
                          !hasMealComposition || draftDuplicate?.[mealTime2]?.duplicationDetail?.isEdited == 0 || !getIsEditableForm
                        "
                        :is-invalid="isInvalidField($v.targets[mealTime2].$each[i].menuType)"
                        @input="
                          (val) => {
                            v.menuType = val
                            v.menuName = null
                            v.menusArray = [
                              {
                                _menuType: val,
                                foodName: null,
                                servingSize: null,
                                servingSizeUnit: null,
                                servingSizeUnitOther: null,
                                servingSizeOther: null,
                                servingSizeOtherUnit: null,
                                photoUrl: null,
                                mealGlossariesId: null,
                              },
                            ]
                            v.menusObject = {
                              _menuType: val,
                              servingSize: null,
                              servingSizeUnit: null,
                              servingSizeUnitOther: null,
                              servingSizeOther: null,
                              servingSizeOtherUnit: null,
                              photoUrl: null,
                              mealGlossariesId: null,
                            }
                          }
                        "
                        @blur="$v.targets[mealTime2].$each[i].menuType.$touch"
                      />
                    </c-box>

                    <!-- Single Entry -->
                    <c-flex
                      v-if="v.menuType === 'singleEntry' || !v.menuType"
                      align-items="flex-start"
                      gap="8px"
                      width="100%"
                    >
                      <c-box
                        min-width="126px"
                        width="100%"
                      >
                        <FormInput
                          size="md"
                          v-model="v.menuName"
                          label=""
                          is-without-label
                          is-without-margin-bottom
                          placeholder="Nama Menu"
                          :is-required="null"
                          :is-disabled="
                            !v.menuType ||
                              !hasMealComposition ||
                              draftDuplicate?.[mealTime2]?.duplicationDetail?.isEdited == 0 ||
                              !getIsEditableForm
                          "
                          :is-invalid="isInvalidField($v.targets[mealTime2].$each[i].menuName)"
                          @blur="$v.targets[mealTime2].$each[i].menuName.$touch"
                        />
                      </c-box>

                      <c-box>
                        <InputTextWithSelect
                          size="md"
                          label=""
                          custom-helper-text-select-other=""
                          input-placeholder="URT"
                          select-placeholder="Pilih"
                          :input-value="v.menusObject.servingSize"
                          :is-invalid-input="
                            isInvalidField($v.targets[mealTime2].$each[i].menusObject.servingSize)
                          "
                          :select-value="v.menusObject.servingSizeUnit"
                          :is-invalid-select="
                            isInvalidField($v.targets[mealTime2].$each[i].menusObject.servingSizeUnit)
                          "
                          :select-other-value="v.menusObject.servingSizeUnitOther"
                          :is-invalid-select-other="
                            isInvalidField(
                              $v.targets[mealTime2].$each[i].menusObject.servingSizeUnitOther
                            )
                          "
                          :select-options="optionPortionUnits"
                          :is-disabled="
                            !v.menuType ||
                              !hasMealComposition ||
                              draftDuplicate?.[mealTime2]?.duplicationDetail?.isEdited == 0 ||
                              !getIsEditableForm
                          "
                          @blur:input="$v.targets[mealTime2].$each[i].menusObject.servingSize.$touch"
                          @blur:select="
                            $v.targets[mealTime2].$each[i].menusObject.servingSizeUnit.$touch
                          "
                          @blur:select-other="
                            $v.targets[mealTime2].$each[i].menusObject.servingSizeUnitOther.$touch
                          "
                          @change:input="(v_) => (v.menusObject.servingSize = v_)"
                          @change:select="(v_) => (v.menusObject.servingSizeUnit = v_)"
                          @change:select-other="
                            (v_) => (v.menusObject.servingSizeUnitOther = v_)
                          "
                          is-without-margin-bottom
                          custom-width-select="90px"
                          custom-width-input="60px"
                          is-without-label
                        />
                      </c-box>
                      <c-box width="150px">
                        <InputTextWithSelect
                          size="md"
                          label=""
                          custom-helper-text-select-other=""
                          input-placeholder="Ukuran Lainnya"
                          select-placeholder="Pilih"
                          :input-value="v.menusObject.servingSizeOther"
                          :is-invalid-input="
                            isInvalidField($v.targets[mealTime2].$each[i].menusObject.servingSizeOther)
                          "
                          :select-value="v.menusObject.servingSizeOtherUnit"
                          :is-invalid-select="
                            isInvalidField($v.targets[mealTime2].$each[i].menusObject.servingSizeOtherUnit)
                          "
                          :select-options="getOptionServingSizeOtherUnits"
                          :is-disabled="
                            !v.menuType ||
                              !hasMealComposition ||
                              draftDuplicate?.[mealTime2]?.duplicationDetail?.isEdited == 0 ||
                              !getIsEditableForm
                          "
                          @blur:input="$v.targets[mealTime2].$each[i].menusObject.servingSizeOther.$touch"
                          @blur:select="
                            $v.targets[mealTime2].$each[i].menusObject.servingSizeOtherUnit.$touch
                          "
                          @change:input="(v_) => (v.menusObject.servingSizeOther = v_)"
                          @change:select="(v_) => (v.menusObject.servingSizeOtherUnit = v_)"
                          is-without-margin-bottom
                          custom-width-select="90px"
                          custom-width-input="60px"
                          is-without-label
                        />
                      </c-box>
                      <c-box>
                        <InputImage2
                          :custom-style="{ width: ['46px','50px'], height: ['46px','50px'] }"
                          :with-bottom-text="false"
                          :photo-url="v.menusObject.photoUrl"
                          :is-disabled="
                            !v.menuType ||
                              !hasMealComposition ||
                              draftDuplicate?.[mealTime2]?.duplicationDetail?.isEdited == 0 ||
                              !getIsEditableForm
                          "
                          @delete:photo="
                            () => {
                              v.menusObject.photoUrl = null
                              v.menusObject.mealGlossariesId = null
                            }
                          "
                          @click:photo="
                            () => {
                              popupIsOpen = true
                              popupMealTime = mealTime2
                              popupItemIndexes = [i]
                            }
                          "
                        />
                      </c-box>
                    </c-flex>

                    <!-- Multiple Entry -->
                    <TestFieldArray
                      v-else-if="v.menuType === 'multipleEntry'"
                      v-slot="{ push: push2, remove: remove2 }"
                    >
                      <c-flex gap="8px">
                        <c-box min-width="115px">
                          <FormInput
                            size="md"
                            v-model="v.menuName"
                            label=""
                            placeholder="Nama Menu"
                            is-without-label
                            is-without-margin-bottom
                            :is-required="null"
                            :is-disabled="
                              !v.menuType ||
                                !hasMealComposition ||
                                draftDuplicate?.[mealTime2]?.duplicationDetail?.isEdited == 0 ||
                                !getIsEditableForm
                            "
                            :is-invalid="isInvalidField($v.targets[mealTime2].$each[i].menuName)"
                            @blur="$v.targets[mealTime2].$each[i].menuName.$touch"
                          />
                        </c-box>
                        <c-box>
                          <c-flex
                            v-for="(v2, i2) in v.menusArray"
                            :key="i2"
                            gap="8px"
                            position="relative"
                            margin-top="14px"
                            :_first="{ marginTop: '0px' }"
                          >
                            <c-flex
                              v-if="true"
                              gap="8px"
                              width="100%"
                              align-items="flex-start"
                            >
                              <c-box
                                width="100%"
                                min-width="115px"
                              >
                                <FormInput
                                  size="md"
                                  is-without-label
                                  is-without-margin-bottom
                                  :is-required="null"
                                  v-model="v2.foodName"
                                  label=""
                                  placeholder="Komposisi"
                                  :is-disabled="
                                    !v.menuType ||
                                      !hasMealComposition ||
                                      draftDuplicate?.[mealTime2]?.duplicationDetail?.isEdited == 0 ||
                                      !getIsEditableForm
                                  "
                                  :is-invalid="
                                    isInvalidField(
                                      $v.targets[mealTime2].$each[i].menusArray.$each[i2].foodName
                                    )
                                  "
                                  @blur="$v.targets[mealTime2].$each[i].menusArray.$each[i2].foodName.$touch"
                                />
                              </c-box>
                              <c-box>
                                <InputTextWithSelect
                                  size="md"
                                  is-without-label
                                  is-without-margin-bottom
                                  :is-required="null"
                                  label=""
                                  custom-helper-text-select-other=""
                                  input-placeholder="URT"
                                  select-placeholder="Pilih"
                                  :input-value="v2.servingSize"
                                  :is-invalid-input="
                                    isInvalidField(
                                      $v.targets[mealTime2].$each[i].menusArray.$each[i2].servingSize
                                    )
                                  "
                                  :select-value="v2.servingSizeUnit"
                                  :is-invalid-select="
                                    isInvalidField(
                                      $v.targets[mealTime2].$each[i].menusArray.$each[i2].servingSizeUnit
                                    )
                                  "
                                  :select-other-value="v2.servingSizeUnitOther"
                                  :is-invalid-select-other="
                                    isInvalidField(
                                      $v.targets[mealTime2].$each[i].menusArray.$each[i2]
                                        .servingSizeUnitOther
                                    )
                                  "
                                  :select-options="optionPortionUnits"
                                  :is-disabled="
                                    !v.menuType ||
                                      !hasMealComposition ||
                                      draftDuplicate?.[mealTime2]?.duplicationDetail?.isEdited == 0 ||
                                      !getIsEditableForm
                                  "
                                  @blur:input="
                                    $v.targets[mealTime2].$each[i].menusArray.$each[i2].servingSize.$touch
                                  "
                                  @blur:select="
                                    $v.targets[mealTime2].$each[i].menusArray.$each[i2].servingSizeUnit
                                      .$touch;
                                  "
                                  @blur:select-other="
                                    $v.targets[mealTime2].$each[i].menusArray.$each[i2].servingSizeUnitOther
                                      .$touch;
                                  "
                                  @change:input="(v_) => (v2.servingSize = v_)"
                                  @change:select="(v_) => (v2.servingSizeUnit = v_)"
                                  @change:select-other="(v_) => (v2.servingSizeUnitOther = v_)"
                                  custom-width-select="90px"
                                  custom-width-input="60px"
                                />
                              </c-box>
                              <c-box width="150px">
                                <InputTextWithSelect
                                  size="md"
                                  is-without-label
                                  is-without-margin-bottom
                                  :is-required="null"
                                  label=""
                                  custom-helper-text-select-other=""
                                  input-placeholder="Ukuran Lainnya"
                                  select-placeholder="Pilih"
                                  :input-value="v2.servingSizeOther"
                                  :is-invalid-input="
                                    isInvalidField(
                                      $v.targets[mealTime2].$each[i].menusArray.$each[i2].servingSizeOther
                                    )
                                  "
                                  :select-value="v2.servingSizeOtherUnit"
                                  :is-invalid-select="
                                    isInvalidField(
                                      $v.targets[mealTime2].$each[i].menusArray.$each[i2].servingSizeOtherUnit
                                    )
                                  "
                                  :select-options="getOptionServingSizeOtherUnits"
                                  :is-disabled="
                                    !v.menuType ||
                                      !hasMealComposition ||
                                      draftDuplicate?.[mealTime2]?.duplicationDetail?.isEdited == 0 ||
                                      !getIsEditableForm
                                  "
                                  @blur:input="
                                    $v.targets[mealTime2].$each[i].menusArray.$each[i2].servingSizeOther.$touch
                                  "
                                  @blur:select="
                                    $v.targets[mealTime2].$each[i].menusArray.$each[i2].servingSizeOtherUnit
                                      .$touch;
                                  "
                                  @change:input="(v_) => (v2.servingSizeOther = v_)"
                                  @change:select="(v_) => (v2.servingSizeOtherUnit = v_)"
                                  custom-width-select="90px"
                                  custom-width-input="60px"
                                />
                              </c-box>
                              <c-box>
                                <InputImage2
                                  :custom-style="{ width: ['46px','50px'], height: ['46px','50px'] }"
                                  :with-bottom-text="false"
                                  :photo-url="v2.photoUrl"
                                  :is-disabled="
                                    !v.menuType ||
                                      !hasMealComposition ||
                                      draftDuplicate?.[mealTime2]?.duplicationDetail?.isEdited == 0 ||
                                      !getIsEditableForm
                                  "
                                  @delete:photo="
                                    () => {
                                      v2.photoUrl = null
                                      v2.mealGlossariesId = null
                                    }
                                  "
                                  @click:photo="
                                    () => {
                                      popupIsOpen = true
                                      popupMealTime = mealTime2
                                      popupItemIndexes = [i, i2]
                                    }
                                  "
                                />
                              </c-box>
                            </c-flex>
                            <c-flex
                              align-items="center"
                              :height="['46px', '50px']"
                              position="absolute"
                              :right="['calc(0px - 8px - 36px)', 'calc(0px - 8px - 40px)']"
                            >
                              <c-button
                                v-if="i2 !== 0"
                                background-color="transparent"
                                :_hover="{ backgroundColor: 'neutral.lightGray' }"
                                margin-left="auto"
                                @click="remove2(v.menusArray, i2)"
                                width="40px"
                                :disabled="draftDuplicate?.[mealTime2]?.duplicationDetail?.isEdited == 0 || !getIsEditableForm"
                              >
                                <c-box
                                  height="25px"
                                  width="25px"
                                >
                                  <inline-svg
                                    :src="require('@/assets/icons/icon-trash.svg')"
                                    height="25px"
                                    width="25px"
                                    fill="#D32737"
                                  />
                                </c-box>
                              </c-button>
                            </c-flex>
                          </c-flex>
                        </c-box>
                      </c-flex>
                      <c-flex
                        justify-content="flex-end"
                        margin-top="14px"
                      >
                        <BaseButton
                          size="small"
                          border-radius="1000px"
                          variant="outlined"
                          background-color="white"
                          :disabled="
                            !v.menuType ||
                              !hasMealComposition ||
                              $v.targets[mealTime2].$invalid ||
                              draftDuplicate?.[mealTime2]?.duplicationDetail?.isEdited == 0 ||
                              !getIsEditableForm
                          "
                          width="100%"
                          max-width="415px"
                          :left-svg-icon="require('@/assets/icons/icon-plus.svg')"
                          left-svg-icon-color="#008C81"
                          @click.prevent="
                            push2(targets[mealTime2][i].menusArray, {
                              _menuType: v.menuType,
                              foodName: null,
                              servingSize: null,
                              servingSizeUnit: null,
                              servingSizeUnitOther: null,
                              servingSizeOther: null,
                              servingSizeOtherUnit: null,
                              photoUrl: null,
                              mealGlossariesId: null,
                            })
                          "
                        >
                          Tambah Komposisi Makanan
                        </BaseButton>
                      </c-flex>
                    </TestFieldArray>

                    <!-- delete button -->
                    <c-flex
                      align-items="center"
                      :height="['46px', '50px']"
                      min-width="50px"
                    >
                      <c-button
                        v-if="targets?.[mealTime2]?.length >= 2"
                        background-color="transparent"
                        :_hover="{ backgroundColor: 'neutral.lightGray' }"
                        :disabled="draftDuplicate?.[mealTime2]?.duplicationDetail?.isEdited == 0 || !getIsEditableForm"
                        padding="0"
                        @click="remove(targets[mealTime2], i)"
                      >
                        <c-box
                          height="25px"
                          width="25px"
                        >
                          <inline-svg
                            :src="require('@/assets/icons/icon-trash.svg')"
                            height="25px"
                            width="25px"
                            fill="#D32737"
                          />
                        </c-box>
                      </c-button>
                    </c-flex>
                  </c-flex>

                  <c-flex justify-content="center">
                    <BaseButton
                      margin="auto"
                      mt="14px"
                      size="small"
                      border-radius="1000px"
                      width="70%"
                      :disabled="
                        !hasMealComposition ||
                          $v.targets[mealTime2].$invalid ||
                          draftDuplicate?.[mealTime2]?.duplicationDetail?.isEdited == 0 ||
                          !getIsEditableForm
                      "
                      color="secondary"
                      :left-svg-icon="require('@/assets/icons/icon-plus.svg')"
                      left-svg-icon-color="#008C81"
                      v-chakra="{
                        color: '#008C81 !important',
                      }"
                      @click.prevent="
                        push(targets[mealTime2], {
                          id: Date.now()?.toString(),
                          menuType: null, // single | multiple
                          menuName: null, // {multipleEntry Only}
                          menusObject: {
                            // {object: singleEntry | array: multipleEntry}
                            servingSize: null,
                            servingSizeUnit: null,
                            photoUrl: null,
                            servingSizeUnitOther: null,
                            servingSizeOther: null,
                            servingSizeOtherUnit: null,
                            mealGlossariesId: null,
                          },
                          menusArray: [
                            {
                              foodName: null,
                              servingSize: null,
                              servingSizeUnit: null,
                              photoUrl: null,
                              servingSizeUnitOther: null,
                              servingSizeOther: null,
                              servingSizeOtherUnit: null,
                              mealGlossariesId: null,
                            },
                          ],
                        })
                      "
                    >
                      Tambah Menu Lainnya
                    </BaseButton>
                  </c-flex>
                </TestFieldArray>
                <c-box
                  as="hr"
                  margin-top="16px"
                />
              </c-box>
            </c-flex>
          </c-flex>
        </c-flex>
      </c-box>
    </c-flex>

    <!-- MODAL -->
    <ModalSimpleInfo
      :is-open="isOpenModalSuccessSaveData"
      :image="require('@/assets/images/success-image.svg')"
      title="Berhasil Menyimpan Data"
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
        >
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="
              $router.push({
                ...$route,
                name: 'nutri.meal-plan.menu-recommendation.detail',
                params: {
                  ...$route.params,
                  bypass: 1,
                },
              })
            "
          >
            Oke
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>

    <ModalSimpleInfo
      :is-open="isOpenModalFailedSaveData"
      :image="require('@/assets/images/image-warning.svg')"
      title="Gagal Menyimpan Data"
      description="Terdapat kesalahan data, coba tinjau ulang datamu"
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
        >
          <BaseButton
            color="primary"
            rounded="1000px"
            width="100%"
            @click="isOpenModalFailedSaveData = false"
          >
            Oke
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>

    <ModalCreateBatchMenuRecommendations
      :client-id2="$route.params.clientId"
      :program-id2="$route.params.programId"
      :month="$route.params.month"
      :chip-days-state="{
        invalidDayValidation: [], // skip invalid state
        disabledDay: chipDaysState.disabledDay,
        mealtimeStates: listMealTimeStates,
      }"
      :option-portion-units="optionPortionUnits"
      :is-open="dataModalCreateBatchMenuRecommendations.isOpen"
      :selected-day-default-value="query.day"
      @close="dataModalCreateBatchMenuRecommendations.isOpen = false"
    />

    <ModalChooseMenu
      :is-open="popupIsOpen"
      title="Pilih Tampilan"
      :is-loading-data-food="popupIsLoadingDataFood"
      :options="popupOptions"
      :selected-foods="popupSelectedMenu"
      :pages="popupPages"
      :filter="popupFilter"
      @change:selected-food="
        (menu) => {
          if (targets?.[popupMealTime]?.[popupItemIndexes?.[0]]?.menusArray?.[popupItemIndexes?.[1]]) {
            targets[popupMealTime][popupItemIndexes[0]].menusArray[popupItemIndexes[1]].photoUrl =menu?.photoUrl || ''
            targets[popupMealTime][popupItemIndexes[0]].menusArray[popupItemIndexes[1]].mealGlossariesId = menu?.id || ''
          } else if (targets?.[popupMealTime]?.[popupItemIndexes?.[0]]?.menusObject) {
            targets[popupMealTime][popupItemIndexes[0]].menusObject.photoUrl = menu?.photoUrl || ''
            targets[popupMealTime][popupItemIndexes[0]].menusObject.mealGlossariesId = menu?.id || ''
          }
          popupIsOpen = false
          popupMealTime = ''
        }
      "
      @change:is-open="(open) => (popupIsOpen = open)"
      @change:filter-page="(page) => (popupFilter.page = page)"
      @change:filter-search="
        (search) => {
          popupFilter.page = 1
          popupFilter.search = search
        }
      "
    />

    <BaseModal
      :is-open="!!isOpenModalEditMenuDuplicate"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            height="120px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          py="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="20px"
            color="primary.400"
          >
            Apakah ingin mengubah data menu duplikasi?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              :is-disabled="isLoadingEditMenuDuplicate"
              @click="onCloseEditMenuDuplicate"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              :is-loading="isLoadingEditMenuDuplicate"
              @click="onSubmitEditMenuDuplicate"
            >
              Ya
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import _ from 'lodash'
import FormInput from '@/views/client/kuisioner/forms/form-input-2'
import BaseButton from '@/components/elements/base-button.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import {
  requiredIf,
  required,
  helpers,
  or,
  decimal,
  maxLength,
} from 'vuelidate/lib/validators'
import { mapGetters, mapMutations } from 'vuex'
import { isInvalidField } from '@/utils/vuelidate-helpers/is-invalid-field'
import { parseErrors } from '@/utils/vuelidate-helpers/parse-errors'
import ModalSimpleInfo from '@/components/elements/modal-simple-info.vue'
import { CBox, CButton, CDivider, CFlex, CText, CTooltip } from '@chakra-ui/vue'
import {
  tabsMealPlan,
  mealPlanSteps,
  mealPlanValueToLabel,
} from '@/constants/meal-plans'
import Info2 from '@/components/info-2.vue'
import MealTimeHeader from '@/components/meal-time-header.vue'
import CardMealPortionGuide from '@/components/card-meal-portion-guide.vue'
import NoData2 from '@/components/no-data-2.vue'
import TestFieldArray from '@/views/profile/detail/test-field-array.vue'
import InputTextWithSelect from '@/components/input-text-with-select.vue'
import FormSelect from '@/views/client/kuisioner/forms/form-select-2.vue'
import ModalChooseMenu from '@/components/modal-choose-menu.vue'
import {
  reqMenuRecommendation_Create,
  reqMenuRecommendation_MealPlanDetail,
  reqMenuRecommendation_PortionUnit,
} from '@/requests/dietela-api/other'
import InputImage2 from '@/components/input-image-2.vue'
import { isDataEmpty } from '@/utils/is-data-empty'
import ChipDays from '@/components/chip-days.vue'
import ModalCreateBatchMenuRecommendations from '@/components/meal-plan/_widgets/modal-create-batch-menu-recommendations.vue'
import ModalMenuDuplicate from '@/components/meal-plan/_widgets/modal-duplicate-menu-in-menu-recommendation.vue'
import { reqNutritionist_mealPlans_menuRecommendation_bulkCreateStatus } from '@/requests/dietela-api/nutritionist/other'
import mixinsTabsMealPlan from '@/mixins/mixins-tabs-meal-plan'
import { format24Time } from '@/utils/format-24-time'
import PanelPreviewMenuRecommendation from '@/components/meal-plan/_widgets/panel-preview-menu-recommendation.vue'
import { dragscroll } from 'vue-dragscroll'

const DEFAULT_DATA_MENU_VALUE = [
  {
    id: Date.now()?.toString(),
    menuType: null, // single | multiple
    menuName: null, // {multipleEntry Only}
    menusObject: {
      // {object: singleEntry | array: multipleEntry}
      _menuType: null,
      servingSize: null,
      servingSizeUnit: null,
      photoUrl: null,
      servingSizeUnitOther: null,
      servingSizeOther: null,
      servingSizeOtherUnit: null,
      mealGlossariesId: null,
    },
    menusArray: [
      {
        _menuType: null,
        foodName: null,
        servingSize: null,
        servingSizeUnit: null,
        photoUrl: null,
        servingSizeUnitOther: null,
        servingSizeOther: null,
        servingSizeOtherUnit: null,
        mealGlossariesId: null,
      },
    ],
  },
]

const fraction = helpers.regex('fraction', /^[1-9][0-9]*\/[1-9][0-9]*$/)
const specialCharacter = helpers.regex('specialCharacter', /[\W\d]+/) // Accepts special characters and numbers

export default {
  name: 'FormMenuRecommendation',
  components: {
    ModalCreateBatchMenuRecommendations,
    ChipDays,
    InputImage2,
    ModalChooseMenu,
    FormSelect,
    InputTextWithSelect,
    CButton,
    CBox,
    TestFieldArray,
    NoData2,
    CardMealPortionGuide,
    MealTimeHeader,
    Info2,
    CFlex,
    CText,
    CDivider,
    CTooltip,
    ModalSimpleInfo,
    FormInput,
    BaseButton,
    BaseText,
    BaseModal,
    ModalMenuDuplicate,
    PanelPreviewMenuRecommendation,
  },
  directives: {
    dragscroll,
  },
  mixins: [mixinsTabsMealPlan],
  props: {
    draft: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update:validation'],
  data() {
    return {
      dataModalCreateBatchMenuRecommendations: {
        isOpen: false,
      },
      popupIsLoadingDataFood: false,
      popupItemIndexes: [],
      popupIsOpen: false,
      popupMealTime: '',
      popupFilter: {
        search: '',
        page: 1,
        limit: 10,
      },
      popupMeta: {
        total: 0,
        page: 0,
        pagesize: 0,
      },
      popupSelectedMenu: {},
      popupPages: [1],
      popupOptions: [],

      photoUrl: null,
      isOpenModalSuccessSaveData: false,
      isOpenModalFailedSaveData: false,
      isLoadingSave: false,

      startTime: '',
      endTime: '',
      caloriesNeedPercent: null,
      caloriesAmount: null,
      mealComposition: [],
      optionPortionUnits: [],
      duplicationDetail: null,
      isSaveDraft: null,

      targets: _.cloneDeep(mealPlanSteps?.reduce((acc, curr)=> {
        acc[curr.value] = DEFAULT_DATA_MENU_VALUE
        return acc
      }, {})),
      targetsDuplicate: _.cloneDeep(mealPlanSteps?.reduce((acc, curr)=> {
        acc[curr.value] = DEFAULT_DATA_MENU_VALUE
        return acc
      }, {})),
      draftDuplicate: _.cloneDeep(this.draft),
      isLoadingData: false,
      currentPreviewDay: null,
      listMealTimeStates: {},
      dataDays: [],
      isOpenModalEditMenuDuplicate: false,
      selectedModalEditMenuDuplicateMealTime: '',
      isLoadingEditMenuDuplicate: false,
      preview: null,
      previewMealPlanSchedule: [],
      selectedPanduanPorsi: {},
    }
  },
  computed: {
    ...mapGetters({
      isValidTabNutritionalProfile: 'mealPlan2/isValidTabNutritionalProfile',
      isValidTabMealPlan: 'mealPlan2/isValidTabMealPlan',
      defaultItems: 'mealPlan/defaultItems',
      saveState: 'general/saveState',
    }),
    getOptionServingSizeOtherUnits() {
      return [{
        value: 'gram',
        label: 'gram',
      },
      {
        value: 'ml',
        label: 'ml',
      }]
    },
    getIsEditableForm() {
      return this.isValidTabNutritionalProfile && this.isValidTabMealPlan
    },
    chipDaysState() {
      return this.dataDays?.reduce(
        (acc, curr) => {
          if (parseInt(curr?.day) === parseInt(this.query.day) && this.isValidAllForm) {
            // skipped
          } else if (
            parseInt(curr?.day) === parseInt(this.query.day) &&
            !this.isValidAllForm
          ) {
            acc.invalidDayValidation?.push(curr?.day)
          } else if (curr?.validation === -1 || curr?.validation === 0) {
            acc.invalidDayValidation?.push(curr?.day)
          }

          if (curr?.isEnabled !== 1) {
            acc.disabledDay.push(curr?.day)
          }

          if (curr?.isBulkCreate) {
            acc.dotBadgeDay.push(curr?.day)
          }
          return acc
        },
        {
          invalidDayValidation: [],
          disabledDay: [],
          dotBadgeDay: [],
        },
      )
    },
    currentActiveDays() {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    query() {
      return {
        mealTime: this.$route.query?.mealTime || '',
        day: this.$route.query?.day || '',
      }
    },
    hasMealComposition() {
      return this.mealComposition?.length >= 1
    },
    targets2() {
      return this.targets
    },
    watchPopupFilterAndMealComposition() {
      return {
        popupFilter: this.popupFilter,
        mealComposition: this.mealComposition,
      }
    },
    constants() {
      return {
        steps: mealPlanSteps,
        tabsMealPlan,
        mealPlanValueToLabel,
      }
    },
    isValidAllForm() {
      return !this.$v.$invalid
    },
    isValidAllFormForAllDays() {
      return (
        this.dataDays
          ?.filter((v) => v?.isEnabled === 1)
          .filter((v) => parseInt(v?.day) !== parseInt(this.query.day))
          ?.every((v) => v?.validation === 1) && this.isValidAllForm
      )
    },
    iconCloud() {
      switch (this.saveState) {
        case true:
          return {
            icon: require('@/assets/icons/icon-cloud-done.svg'),
            text: 'Semua perubahan telah disimpan',
          }
        case false:
          return {
            icon: require('@/assets/icons/icon-cloud-off.svg'),
            text: 'Koneksi terputus, perubahan tidak tersimpan',
          }
        default:
          return {
            icon: require('@/assets/icons/icon-sync.svg'),
            text: 'Loading...',
          }
      }
    },
    optionsPanduanPorsi() {
      return this.previewMealPlanSchedule.map((item) => {
        return {
          id: item?.mealTime,
          label: this.constants.mealPlanValueToLabel[item?.mealTime],
          disabled: false,
        }
      })
    },
  },
  watch: {
    watchPopupFilterAndMealComposition: {
      // Fetch Popup Content
      async handler(value) {
        this.popupIsLoadingDataFood = true
        const resPopupContent = await reqNutriMealPlan.getMealGlossaries(
          this.$store.getters.axios,
          {
            q: value?.popupFilter?.search,
            page: value?.popupFilter?.page,
            perpage: value?.popupFilter?.limit,
          },
        )
        const dataPopupContent = resPopupContent.data
        if (dataPopupContent) {
          this.popupMeta = dataPopupContent?.meta
          this.popupOptions = dataPopupContent?.data?.result
          this.popupPages = Array.from(
            { length: Math.ceil(this.popupMeta.total / this.popupMeta.pagesize) },
            (_, i) => i + 1,
          )
        }
        this.popupIsLoadingDataFood = false
      },
      immediate: true,
      deep: true,
    },
    targets2: {
      handler() {
        this.setSaveState(null)
      },
      deep: true,
    },
    targets: {
      handler: _.debounce(function() {
        const data = {
          form: 'menu-recommendation',
          validation: this.isValidAllForm ? 1 : -1,
        }
        this.$emit('update:validation', data)

        this.onSaveDraft()
      }, 3000),
      deep: true,
    },
  },
  async mounted() {
    this.isLoadingData = true

    // set default "currentPreviewDay" data
    this.currentPreviewDay = this.query.day >= 8 ? 2 : 1
    await this.getFormDays()

    // Fetch Preview Data
    const resDetailPreview = await reqMenuRecommendation_MealPlanDetail(
      this.$store.getters.axios,
      {
        clientId: this.$route.params.clientId,
        programId: this.$route.params.programId,
        month: this.$route.params.month,
      },
    )

    this.preview = resDetailPreview.data?.data

    // Set Data Preview Meal Plan Schedule
    this.previewMealPlanSchedule = resDetailPreview?.data?.data?.filter((item) => item?.isSkipped === false) || []

    // Get List Meal Time States
    this.listMealTimeStates =
      resDetailPreview.data?.data?.reduce((acc, curr) => {
        acc[curr?.mealTime] = {
          isSkipped: curr?.isSkipped,
          isValid: curr?.validation === 1,
        }
        return acc
      }, {}) || {}
    const dataDetailPreview =
      resDetailPreview.data?.data?.find((v) => v?.mealTime === this.query.mealTime) || {}
    if (dataDetailPreview) {
      this.startTime = dataDetailPreview?.startTime
      this.endTime = dataDetailPreview?.endTime
      this.caloriesNeedPercent = dataDetailPreview?.caloriesNeedPercent
      this.caloriesAmount = dataDetailPreview?.caloriesAmount
      this.mealComposition = dataDetailPreview?.mealComposition
    }

    // Fetch Portion Unit
    const resPortionUnit = await reqMenuRecommendation_PortionUnit(
      this.$store.getters.axios,
    )
    const dataPortionUnit = resPortionUnit.data?.data
    let tempForCheckDuplicate = {}
    if (dataPortionUnit) {
      this.optionPortionUnits =
        dataPortionUnit?.allUnit?.reduce((acc, curr) => {
          if (tempForCheckDuplicate[curr?.value]) {
            return acc
          }
          tempForCheckDuplicate[curr?.value] = true
          acc.push({
            value: curr?.value,
            label: curr?.value,
          })

          return acc
        }, []) || []
      this.optionPortionUnits.push({
        value: 'Lainnya',
        label: 'Lainnya',
      })
    }

    // Get Data List Menu
    this.isSaveDraft = this.draft?.isSaveDraf
    this.targets = this.constants.steps?.reduce((acc, curr) => {
      if (this.draft?.[curr.value]?.dataMenu?.length>= 1) {
        acc[curr.value] = this.parseDataMenuPayloadToComponent(this.draft?.[curr.value]?.dataMenu || [])
      } else {
        acc[curr.value] = _.cloneDeep(DEFAULT_DATA_MENU_VALUE)
      }
      return acc
    }, {})
    this.targetsDuplicate = _.cloneDeep(this.targets)
    this.duplicationDetail = this.draft?.[this.query.mealTime]?.duplicationDetail

    if (this.draft?.isBulkCreate) {
      this.updateBulkStatus()
    }
  },
  methods: {
    format24Time,
    ...mapMutations({
      setSaveState: 'general/setSaveState',
      setEditValidation: 'mealPlan/setEditValidation',
    }),
    photoValidation(value, parentValue) {
      if (!parentValue?.hasPhoto || !parentValue?.isEat) {
        return true
      }
      return value.some((v) => !isDataEmpty(v))
    },
    mergeMealItems(portionDecimal, mealItems, additionalMealItems) {
      const remapMealItems =
        mealItems?.map((v) => {
          return {
            ...v,
            servingSize: parseFloat(v?.householdMeasurement || 0),
            servingSizeUnit: v?.householdMeasurementUnit,
          }
        }) || []
      const remapAdditionalMealItems =
        additionalMealItems?.map((v) => {
          return {
            ...v,
            servingSize:
              parseFloat(v?.householdMeasurementPerPortion || 0) *
              parseFloat(portionDecimal || 0),
            servingSizeUnit: v?.householdMeasurementUnitPerPortion,
          }
        }) || []

      return remapMealItems.concat(remapAdditionalMealItems)
    },
    getPreviewImage(url) {
      if (this.photoUrl) {
        return URL.createObjectURL(this.photoUrl)
      }
      if (url) {
        return url
      }
      return require('@/assets/icon-photo.svg')
    },
    isInvalidField,
    async getFormDays() {
      // Fetch days
      const resDays = await reqNutriMealPlan.getMenuRecommendationFormDays(
        this.$store.getters.axios,
        {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: this.$route.params.month,
        },
      )
      this.dataDays = resDays.data?.data
    },
    async onSave() {
      try {
        await this.onSaveDraft(1)
        this.isOpenModalSuccessSaveData = true
      } catch (err) {
        this.isOpenModalFailedSaveData = true
      }
    },
    parseErrors,
    ...mapMutations({
      setDefaultItems: 'mealPlan/setDefaultItems',
    }),
    parseDataMenuComponentToPayload(dataMenu) {
      return dataMenu.map((v) => {
        return {
          menuType: v?.menuType,
          menuName: v?.menuName,
          menus:
            v?.menuType === 'singleEntry'
              ? {
                servingSize: v?.menusObject?.servingSize,
                servingSizeUnit: v?.menusObject?.servingSizeUnit,
                photoUrl: v?.menusObject?.photoUrl,
                servingSizeUnitOther: v?.menusObject?.servingSizeUnitOther,
                servingSizeOther: v?.menusObject?.servingSizeOther,
                servingSizeOtherUnit: v?.menusObject?.servingSizeOtherUnit,
                mealGlossariesId: v?.menusObject?.mealGlossariesId,
              }
              : v?.menusArray?.map((v2) => {
                return {
                  foodName: v2?.foodName,
                  servingSize: v2?.servingSize,
                  servingSizeUnit: v2?.servingSizeUnit,
                  photoUrl: v2?.photoUrl,
                  servingSizeUnitOther: v2?.servingSizeUnitOther,
                  servingSizeOther: v2?.servingSizeOther,
                  servingSizeOtherUnit: v2?.servingSizeOtherUnit,
                  mealGlossariesId: v2?.mealGlossariesId,
                }
              }),
        }
      })
    },
    parseDataMenuPayloadToComponent(dataMenu) {
      return dataMenu?.map((v) => {
        return {
          menuType: v?.menuType,
          menuName: v?.menuName,
          menusObject: {
            _menuType: v?.menuType,
            servingSize: v?.menus?.servingSize,
            servingSizeUnit: v?.menus?.servingSizeUnit,
            photoUrl: v?.menus?.photoUrl,
            servingSizeUnitOther: v?.menus?.servingSizeUnitOther,
            servingSizeOther: v?.menus?.servingSizeOther || v?.menus?.servingSizeOtherPerGram,
            servingSizeOtherUnit: v?.menus?.servingSizeOtherUnit || this.getOptionServingSizeOtherUnits?.find((v) => v.value === 'gram')?.value,
            mealGlossariesId: v?.menus?.mealGlossariesId,
          },
          menusArray:
            v?.menus?.length >= 1
              ? v?.menus?.map((v2) => {
                return {
                  _menuType: v?.menuType,
                  foodName: v2?.foodName,
                  servingSize: v2?.servingSize,
                  servingSizeUnit: v2?.servingSizeUnit,
                  photoUrl: v2?.photoUrl,
                  servingSizeUnitOther: v2?.servingSizeUnitOther,
                  servingSizeOther: v2?.servingSizeOther || v?.menus?.servingSizeOtherPerGram,
                  servingSizeOtherUnit: v2?.servingSizeOtherUnit || this.getOptionServingSizeOtherUnits?.find((v) => v.value === 'gram')?.value,
                  mealGlossariesId: v2?.mealGlossariesId,
                }
              })
              : DEFAULT_DATA_MENU_VALUE[0].menusArray,
        }
      })
    },
    async onSaveDraft(isSaveDraf = 0) {
      // nyalakan lagi nanti
      try {
        this.isLoadingSave = true
        this.setSaveState(null)

        let payload = {
          // ...this.draft,
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: this.$route.params.month,
          isDraft: 1,
          isSaveDraf: isSaveDraf,
          day: this.query.day,
          validation: this.isValidAllForm ? 1 : -1, // {-1 | 1}
          ...Object.entries(this.targets)?.reduce((acc, [currKey, currValue]) => {
            const dataDetailPreview = this.preview?.find((v) => v?.mealTime === currKey) || {}
            if (this.listMealTimeStates?.[currKey]?.isSkipped) {
              acc[currKey] = {
                isSkipped: true,
                validation: 1,
                duplicationDetail: null,
                startTime: null,
                endTime: null,
                caloriesNeedPercent: null,
                caloriesAmount: null,
                dataMenu: null,
              }
            } else {
              acc[currKey] = {
                isSkipped: false,
                validation: this.$v.$invalid ? -1 : 1,
                duplicationDetail: this.draftDuplicate?.[currKey]?.duplicationDetail,
                startTime: dataDetailPreview.startTime,
                endTime: dataDetailPreview.endTime,
                caloriesNeedPercent: dataDetailPreview.caloriesNeedPercent,
                caloriesAmount: dataDetailPreview.caloriesAmount,
                dataMenu: this.parseDataMenuComponentToPayload(currValue),
              }
            }
            return acc
          }, {}),
        }

        this.isSaveDraft = isSaveDraf

        await reqMenuRecommendation_Create(this.$store.getters.axios, payload)
        this.$v.$touch()

        this.setSaveState(true)

        this.setEditValidation({
          routeName: this.$route.name,
          validation: this.isValidAllForm ? 1 : -1,
        })
      } catch (err) {
        this.setSaveState(false)
        this.isOpenModalFailedSaveData = true
      } finally {
        this.isLoadingSave = false
      }
    },
    onOpenEditMenuDuplicate(mealTime) {
      this.selectedModalEditMenuDuplicateMealTime = mealTime
      this.isOpenModalEditMenuDuplicate = true
    },
    onCloseEditMenuDuplicate() {
      if (!this.isLoadingEditMenuDuplicate) {
        this.selectedModalEditMenuDuplicateMealTime = ''
        this.isOpenModalEditMenuDuplicate = false
      }
    },
    async onSubmitEditMenuDuplicate() {
      try {
        this.isLoadingEditMenuDuplicate = true
        if (this.draftDuplicate?.[this.selectedModalEditMenuDuplicateMealTime]?.duplicationDetail?.isEdited) {
          this.draftDuplicate[this.selectedModalEditMenuDuplicateMealTime].duplicationDetail.isEdited = 1
        }
        await this.onSaveDraft()
      } finally {
        this.isLoadingEditMenuDuplicate = false
        this.onCloseEditMenuDuplicate()
      }
    },
    async updateBulkStatus() {
      const menuRecommendationId = this.draft?.id
      await reqNutritionist_mealPlans_menuRecommendation_bulkCreateStatus(
        this.$store.getters.axios,
        {
          id: menuRecommendationId,
        },
      )
      this.getFormDays()
    },
    onChangePanduanPorsi(value) {
      this.$scrollTo(`#portion-guide-${value?.id}`, 500, {
        container: '#container-portion-guide',
        offset: 0,
      })
      this.selectedPanduanPorsi = value
    },
  },
  validations() {
    return {
      targets: {
        ...this.constants.steps?.reduce((acc, curr) => {
          if (this.listMealTimeStates?.[curr.value]?.isSkipped) return acc
          acc[curr.value] = {
            $each: {
              menuType: {
                required,
              },
              menuName: {
                required,
              },
              menusObject: {
                servingSize: {
                  required: requiredIf((it) => it._menuType === 'singleEntry'),
                  decimalFraction: or(decimal, fraction, specialCharacter),
                },
                servingSizeUnit: {
                  required: requiredIf((it) => it._menuType === 'singleEntry'),
                },
                servingSizeUnitOther: {
                  required: requiredIf((it) => it.servingSizeUnit === 'Lainnya'),
                  maxLength: maxLength(20),
                },
                servingSizeOther: {
                  // empty
                },
                servingSizeOtherUnit: {
                  // empty
                },
              },
              menusArray: {
                $each: {
                  foodName: {
                    required: requiredIf((it) => it._menuType === 'multipleEntry'),
                  },
                  servingSize: {
                    required: requiredIf((it) => it._menuType === 'multipleEntry'),
                    decimalFraction: or(decimal, fraction, specialCharacter),
                  },
                  servingSizeUnit: {
                    required: requiredIf((it) => it._menuType === 'multipleEntry'),
                  },
                  servingSizeUnitOther: {
                    required: requiredIf((it) => it.servingSizeUnit === 'Lainnya'),
                    maxLength: maxLength(20),
                  },
                  servingSizeOther: {
                    // empty
                  },
                  servingSizeOtherUnit: {
                    // empty
                  },
                },
              },
            },
          }
          return acc
        }, {}),

      },
    }
  },
}
</script>
