<template>
  <c-box
    width="100%"
    border-radius="8px"
    box-shadow="0px 4px 8px rgba(0, 0, 0, 0.2)"
  >
    <c-flex
      position="relative"
      align-items="center"
      justify-content="space-between"
      padding-block="8px"
      padding-inline="28px"
      background-color="#C7F9E333"
      border-bottom-width="1px"
      botder-bottom-color="neutral.lightGray"
    >
      <c-button
        variant="ghost"
        variant-color="primary"
        position="absolute"
        left="2px"
        height="24px"
        min-width="24px"
        width="24px"
        padding="0"
        border-radius="100%"
        @click="onScrollContent(`panel-preview-${mealTime}`, 'left', 20, 24, 4)"
      >
        <c-icon
          size="24px"
          name="chevron-left"
        />
      </c-button>
      <c-flex
        :ref="`panel-preview-${mealTime}`"
        :id="`panel-preview-${mealTime}`"
        v-dragscroll
        flex-grow="1"
        justify-content="flex-start"
        align-items="center"
        gap="1px"
        overflow-x="hidden"
        scroll-behavior="smooth"
      >
        <c-flex
          v-for="i in totalDays"
          :key="i"
          :id="`panel-preview-${mealTime}-${i}`"
          justify-content="center"
          align-items="center"
          flex-shrink="0"
          :width="selectedDay == i ? '28px' : '24px'"
          :height="selectedDay == i ? '28px' : '24px'"
          border-radius="100%"
          font-size="18px"
          font-weight="600"
          :background-color="selectedDay == i ? 'primary.400' : 'transparent'"
          :color="selectedDay == i ? 'white' : 'neutral.black'"
          cursor="pointer"
          :_hover="{
            backgroundColor: selectedDay == i ? 'primary.500' : 'primary.50',
          }"
          @click="onChangeDay(i)"
        >
          {{ i }}
        </c-flex>
      </c-flex>
      <c-button
        variant="ghost"
        variant-color="primary"
        position="absolute"
        right="2px"
        height="24px"
        min-width="24px"
        width="24px"
        padding="0"
        border-radius="100%"
        @click="onScrollContent(`panel-preview-${mealTime}`, 'right', 20, 24, 4)"
      >
        <c-icon
          size="24px"
          name="chevron-right"
        />
      </c-button>
    </c-flex>
    <c-box
      padding="8px"
    >
      <c-flex
        v-if="isLoadingData"
        flex-direction="column"
        justify-content="center"       
        align-items="center"
        gap="8px"
      >
        <c-spinner
          thickness="4px"
          speed="0.65s"
          empty-color="green.200"
          color="primary.400"
          size="lg"
        />
        <c-text
          color="primary.400"
          :font-size="['12px','16px']"
        >
          Memuat data...
        </c-text>
      </c-flex>
      <ListMenuRecommendation
        v-else-if="recommendations.length > 0"
        :items="recommendations"
      />
      <c-flex
        v-else
        align-items="center"
        gap="8px"
      >
        <c-box
          :min-height="['14px', '18px']"
          :min-width="['14px', '18px']"
          :height="['14px', '18px']"
          :width="['14px', '18px']"
          color="#DA6D06"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-info-2.svg')"
            height="100%"
            width="100%"
          />
        </c-box>
        <c-text
          :font-size="['12px','16px']"
          color="warning.400"
        >
          Data belum tersedia
        </c-text>
      </c-flex>
    </c-box>
  </c-box>
</template>

<script>
import { dragscroll } from 'vue-dragscroll'
import ListMenuRecommendation from '@/components/meal-plan/_widgets/list-menu-recommendation.vue'
import { reqNutri_mealPlans_menuRecommendation_previewMealtime } from '@/requests/dietela-api/nutritionist/meal-plans'

export default {
  name: 'PanelPreviewMenuRecommendation',
  components: {
    ListMenuRecommendation,
  },
  directives: {
    dragscroll,
  },
  props: {
    idProgram: {
      type: String,
      required: true,
    },
    idClient: {
      type: String,
      required: true,
    },
    month: {
      type: String,
      required: true,
    },
    mealTime: {
      type: String,
      required: true,
    },
    day: {
      type: [Number, String],
      default: 1,
    },
    totalDays: {
      type: Number,
      default: 10,
    },
    refetch: {
      type: [Boolean, null],
      default: false,
    },
  },
  data() {
    return {
      isLoadingData: false,
      recommendations: [],
      selectedDay: 0,
    }
  },
  computed: {
  },
  watch: {
    refetch: {
      handler(val) {
        // refetch preview rekomendasi menu when selected day is the same as the current day
        if (val && this.selectedDay == this.day) {
          this.fetchPreviewMenuRecommendation()
        }
      },
    },
  },
  async mounted() {
    this.onChangeDay(this.day)
    this.onScrollContent(`panel-preview-${this.mealTime}`, 'right', 20, 24, this.day > 5 ? 80 : 0) // scroll to selected day
  },
  methods: {
    async fetchPreviewMenuRecommendation() {
      try {
        const res = await reqNutri_mealPlans_menuRecommendation_previewMealtime(this.$store.getters.axios, {
          programId: this.idProgram,
          clientId: this.idClient,
          month: this.month,
          mealTime: this.mealTime,
          day: this.selectedDay,
        })
        if (res.data?.data[this.mealTime]?.dataMenu) {
          this.recommendations = res.data.data[this.mealTime].dataMenu
        }
      } catch(err) {
        console.log(err)
      } finally {
        this.isLoadingData = false
      }
    },
    onChangeDay(day) {
      this.selectedDay = day
      this.isLoadingData = true
      this.recommendations = []
      this.fetchPreviewMenuRecommendation()
    },
    onScrollContent(el, direction, speed, distance, step) {
      var scrollAmount = 0
      const element = this.$refs[el]
      var slideTimer = setInterval(function() {
        if (direction == 'left') {
          element.$el.scrollLeft -= step
        } else {
          element.$el.scrollLeft += step
        }
        scrollAmount += step
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer)
        }
      }, speed)
    },
  },
}
</script>

<style scoped>
</style>